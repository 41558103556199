import React from 'react';

import Icon from './Icon';

export default function NavFolderClosedIcon({
  className = '',
  text = 'Folder',
}: { className?: string; text?: string }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 7V14C3 15.1046 3.89543 16 5 16H15C16.1046 16 17 15.1046 17 14V9C17 7.89543 16.1046 7 15 7H11M3 7V5.5C3 4.67157 3.67157 4 4.5 4H9L11 7M3 7H11"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
}
